<template>
  <v-row>
    <v-col cols="12">
      <v-card>
        <v-card-title>
          Empresas
          <v-spacer></v-spacer>
          <v-menu
            bottom
            left
          >
            <template v-slot:activator="{ attrs }">
              <v-btn
                color="info"
                outlined
                elevation="5"
                v-bind="attrs"
                @click="createCompany"
              >
                <v-icon class="mr-2">
                  {{ mdiPlaylistPlus }}
                </v-icon>
                Novo
              </v-btn>
            </template>
          </v-menu>
        </v-card-title>

        <v-row>
          <v-col cols="12">
            <v-simple-table>
              <thead>
                <tr>
                  <th
                    v-for="header in headers"
                    :key="header"
                  >
                    {{ header.text }}
                  </th>
                </tr>
              </thead>
              <tbody
                v-if="load.status"
              >
                <tr>
                  <td :colspan="headers.length">
                    <v-progress-linear
                      indeterminate
                    />
                    <v-row justify="center">
                      <v-subheader>
                        {{ load.message }}
                      </v-subheader>
                    </v-row>
                  </td>
                </tr>
              </tbody>
              <tbody
                v-else
              >
                <tr
                  v-for="item in companies"
                  :key="item.id"
                >
                  <td
                    v-for="i in headers"
                    :key="i"
                  >
                    {{ item[i.value] }}

                    <template v-if="i.text === 'OPÇÕES'">
                      <v-btn
                        :loading="item.id === loadModal ? true : false"
                        fab
                        text
                        color="info"
                        x-small
                        @click="getCompanyInfo(item)"
                      >
                        <v-icon
                          size="20"
                        >
                          {{ mdiPencil }}
                        </v-icon>
                      </v-btn>
                      <v-btn
                        fab
                        text
                        color="error"
                        x-small
                        class="ml-2"
                        @click="deleteCompany(item)"
                      >
                        <v-icon
                          size="20"
                        >
                          {{ mdiDelete }}
                        </v-icon>
                      </v-btn>
                    </template>
                  </td>
                </tr>
              </tbody>
            </v-simple-table>
          </v-col>
          <v-col cols="12">
            <v-pagination
              v-model="page"
              circle
              :length="totalPages"
              total-visible="10"
              @input="next"
            />
          </v-col>
        </v-row>
      </v-card>
    </v-col>
    <v-dialog
      v-model="modal"
      width="900"
    >
      <company-modal
        v-if="company"
        :company="company"
        :close-modal="closeModal"
        :company-id="companyId"
        :edit="edit"
      />
    </v-dialog>
  </v-row>
</template>

<script>

// eslint-disable-next-line import/no-cycle
import axiosIns from '@/plugins/axios'
// eslint-disable-next-line import/no-cycle
import formatters from '@/plugins/formattersMixin1'
import { mdiDelete, mdiPencil, mdiPlaylistPlus } from '@mdi/js'
import CompanyModal from './companyModal.vue'

export default {
  components: {
    CompanyModal,
  },

  mixins: [formatters],

  setup() {
    return {
      mdiPencil,
      mdiDelete,
      mdiPlaylistPlus,
    }
  },

  data() {
    return {
      loadModal: '',
      companyId: '',
      company: {},
      modal: false,
      edit: false,
      headers: [
        { text: 'EMPRESA', value: 'corporate_name' },
        { text: 'CNPJ', value: 'cnpj' },
        { text: 'CIDADE', value: 'city' },
        { text: 'ESTADO', value: 'state' },
        { text: 'REGIÃO', value: 'region' },
        { text: 'OPÇÕES', value: 'options', align: 'center' },
      ],

      companies: [],
      page: 1,
      totalPages: 0,
      load: {
        status: true,
        message: '',
      },
    }
  },

  created() {
    this.next()
  },

  methods: {
    clearModal() {
      this.edit = false
      this.company = {
        cnpj: '',
        corporate_name: '',
        fantasy_name: '',
        address: '',
        number: '',
        complement: '',
        district: '',
        city: '',
        state: '',
        zip_code: '',
        regions_id: '',
      }
    },

    async createCompany() {
      this.clearModal()
      this.modal = true
    },

    closeModal() {
      this.modal = false
      this.next()
    },

    async getCompanyInfo(item) {
      this.companyId = item.id
      this.loadModal = item.id
      await axiosIns.get(`/api/v1/records/company/edit/${item.id}`).then(async res => {
        this.company = res.data.data
        this.modal = true
        this.edit = true
        this.loadModal = ''
      })
    },

    async deleteCompany(item) {
      await this.$swal({
        icon: 'warning',
        title: 'Deseja excluir o Empresa?',
        text: 'A operação é irreversível, tem certeza?',
        showCancelButton: true,
        confirmButtonText: 'Sim, excluir!',
        cancelButtonText: 'Não, cancelar!',
      })
        .then(async result => {
          if (result.isConfirmed) {
            await axiosIns.delete(`/api/v1/records/company/destroy/${item.id}`).then(async () => {
              await this.$swal({
                icon: 'success',
                title: 'Empresa excluída!',
                text: 'A empresa foi excluída!',
                showConfirmButton: false,
                timer: 2000,
              })
            })
          } else {
            await this.$swal({
              icon: 'error',
              title: 'Cancelado!',
              text: 'Operação cancelada',
              showConfirmButton: false,
              timer: 2000,
            })
          }
        })
        .catch(async error => {
          await this.$swal({
            icon: 'error',
            title: 'Erro ao excluir empresa!',
            text: error,
          })
        })
    },

    async next() {
      this.companies = []
      this.load = {
        status: true,
        message: 'Carregando dados... Por favor, aguarde',
      }
      await axiosIns.get(`/api/v1/records/company/index?page=${this.page}`).then(res => {
        res.data.data.map(key => {
          this.companies.push({
            id: key.id,
            corporate_name: key.corporate_name,
            cnpj: key.cnpj,
            city: key.city,
            state: key.state,
            region: key.region.region,
          })
          this.load.status = false
          this.totalPages = res.data.meta.last_page
        })
      })
    },
  },
}
</script>
