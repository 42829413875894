import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VMenu } from 'vuetify/lib/components/VMenu';
import { VPagination } from 'vuetify/lib/components/VPagination';
import { VProgressLinear } from 'vuetify/lib/components/VProgressLinear';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSimpleTable } from 'vuetify/lib/components/VDataTable';
import { VSpacer } from 'vuetify/lib/components/VGrid';
import { VSubheader } from 'vuetify/lib/components/VSubheader';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VRow,[_c(VCol,{attrs:{"cols":"12"}},[_c(VCard,[_c(VCardTitle,[_vm._v(" Empresas "),_c(VSpacer),_c(VMenu,{attrs:{"bottom":"","left":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var attrs = ref.attrs;
return [_c(VBtn,_vm._b({attrs:{"color":"info","outlined":"","elevation":"5"},on:{"click":_vm.createCompany}},'v-btn',attrs,false),[_c(VIcon,{staticClass:"mr-2"},[_vm._v(" "+_vm._s(_vm.mdiPlaylistPlus)+" ")]),_vm._v(" Novo ")],1)]}}])})],1),_c(VRow,[_c(VCol,{attrs:{"cols":"12"}},[_c(VSimpleTable,[_c('thead',[_c('tr',_vm._l((_vm.headers),function(header){return _c('th',{key:header},[_vm._v(" "+_vm._s(header.text)+" ")])}),0)]),(_vm.load.status)?_c('tbody',[_c('tr',[_c('td',{attrs:{"colspan":_vm.headers.length}},[_c(VProgressLinear,{attrs:{"indeterminate":""}}),_c(VRow,{attrs:{"justify":"center"}},[_c(VSubheader,[_vm._v(" "+_vm._s(_vm.load.message)+" ")])],1)],1)])]):_c('tbody',_vm._l((_vm.companies),function(item){return _c('tr',{key:item.id},_vm._l((_vm.headers),function(i){return _c('td',{key:i},[_vm._v(" "+_vm._s(item[i.value])+" "),(i.text === 'OPÇÕES')?[_c(VBtn,{attrs:{"loading":item.id === _vm.loadModal ? true : false,"fab":"","text":"","color":"info","x-small":""},on:{"click":function($event){return _vm.getCompanyInfo(item)}}},[_c(VIcon,{attrs:{"size":"20"}},[_vm._v(" "+_vm._s(_vm.mdiPencil)+" ")])],1),_c(VBtn,{staticClass:"ml-2",attrs:{"fab":"","text":"","color":"error","x-small":""},on:{"click":function($event){return _vm.deleteCompany(item)}}},[_c(VIcon,{attrs:{"size":"20"}},[_vm._v(" "+_vm._s(_vm.mdiDelete)+" ")])],1)]:_vm._e()],2)}),0)}),0)])],1),_c(VCol,{attrs:{"cols":"12"}},[_c(VPagination,{attrs:{"circle":"","length":_vm.totalPages,"total-visible":"10"},on:{"input":_vm.next},model:{value:(_vm.page),callback:function ($$v) {_vm.page=$$v},expression:"page"}})],1)],1)],1)],1),_c(VDialog,{attrs:{"width":"900"},model:{value:(_vm.modal),callback:function ($$v) {_vm.modal=$$v},expression:"modal"}},[(_vm.company)?_c('company-modal',{attrs:{"company":_vm.company,"close-modal":_vm.closeModal,"company-id":_vm.companyId,"edit":_vm.edit}}):_vm._e()],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }