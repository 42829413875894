<template>
  <v-card>
    <v-card-title>
      Informações da Empresa
    </v-card-title>
    <v-card-text>
      <v-row>
        <v-col cols="12">
          <v-card flat>
            <v-card-text>
              <v-row>
                <v-col
                  cols="6"
                  class="py-0"
                >
                  <v-text-field
                    v-model="company.corporate_name"
                    outlined
                    dense
                    label="Nome Corporativo"
                    variant="underlined"
                  />
                </v-col>
                <v-col
                  cols="6"
                  class="py-0"
                >
                  <v-text-field
                    v-model="company.fantasy_name"
                    outlined
                    dense
                    label="Nome Fantasia"
                    variant="underlined"
                  />
                </v-col>
                <v-col
                  cols="6"
                  class="py-0"
                >
                  <v-text-field
                    v-model="company.cnpj"
                    outlined
                    dense
                    label="CNPJ"
                    variant="underlined"
                  />
                </v-col>
                <v-col
                  cols="6"
                  class="py-0"
                >
                  <v-text-field
                    v-model="company.address"
                    outlined
                    dense
                    label="Endereço"
                    variant="underlined"
                  />
                </v-col>
                <v-col
                  cols="6"
                  class="py-0"
                >
                  <v-text-field
                    v-model="company.number"
                    outlined
                    dense
                    label="Número"
                    variant="underlined"
                  />
                </v-col>
                <v-col
                  cols="6"
                  class="py-0"
                >
                  <v-text-field
                    v-model="company.complement"
                    outlined
                    dense
                    label="Complemento"
                    variant="underlined"
                  />
                </v-col>
                <v-col
                  cols="6"
                  class="py-0"
                >
                  <v-text-field
                    v-model="company.zip_code"
                    outlined
                    dense
                    label="CEP"
                    variant="underlined"
                  />
                </v-col>
                <v-col
                  cols="6"
                  class="py-0"
                >
                  <v-text-field
                    v-model="company.district"
                    outlined
                    dense
                    label="Bairro"
                    variant="underlined"
                  />
                </v-col>
                <v-col
                  cols="6"
                  class="py-0"
                >
                  <v-text-field
                    v-model="company.city"
                    outlined
                    dense
                    label="Cidade"
                    variant="underlined"
                  />
                </v-col>
                <v-col
                  cols="6"
                  class="py-0"
                >
                  <v-text-field
                    v-model="company.state"
                    outlined
                    dense
                    label="Estado"
                    variant="underlined"
                  />
                </v-col>
                <v-col
                  cols="6"
                  class="py-0"
                >
                  <v-select
                    v-model="company.regions_id"
                    item-value="id"
                    :items="regions"
                    item-text="region"
                    outlined
                    dense
                    label="Região"
                    variant="underlined"
                  />
                </v-col>
              </v-row>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
      <v-row justify="end">
        <v-col
          v-if="edit"
          cols="auto"
        >
          <v-btn
            color="success"
            prepend-icon="mdi-content-save"
            @click="saveChanges"
          >
            salvar
          </v-btn>
        </v-col>
        <v-col
          v-else
          cols="auto"
        >
          <v-btn
            color="info"
            prepend-icon="mdi-plus"
            @click="createCompany"
          >
            cadastrar
          </v-btn>
        </v-col>
      </v-row>
    </v-card-text>
  </v-card>
</template>
<script>
import axiosIns from '@/plugins/axios'

export default {
  props: {
    edit: {
      type: Boolean,
      default: false,
    },
    companyId: {
      type: String,
      default: '',
    },
    company: {
      type: Object,
      default: () => {},
    },
    closeModal: {
      type: Function,
      default: () => {},
    },
  },
  data() {
    return {
      regions: {},
    }
  },
  created() {
    console.log(this.company)
    this.getRegions()
  },
  methods: {
    async getRegions() {
      try {
        const res = await axiosIns.get('/api/v1/records/region/index')
        this.regions = res.data.data
        console.log(res.data.data)
      } catch (error) {
        console.log(error)
      }
    },
    async createCompany() {
      await axiosIns
        .post('/api/v1/records/company/store', this.company)
        .then(async () => {
          await this.closeModal()
          await this.$swal({
            icon: 'success',
            title: 'Empresa cadastrado!',
            text: 'Nova empresa cadastrado!',
            showConfirmButton: false,
            timer: 2000,
          })
        })
        .catch(async error => {
          await this.closeModal()
          await this.$swal({
            icon: 'error',
            title: 'Erro ao cadastrar empresa!',
            showConfirmButton: false,
            text: error,
          })
        })
    },

    async saveChanges() {
      await axiosIns
        .put(`/api/v1/records/company/update/${this.companyId}`, this.company)
        .then(async () => {
          await this.closeModal()
          await this.$swal({
            icon: 'success',
            title: 'Empresa atualizado!',
            text: 'Dados da empresa atualizada!',
            showConfirmButton: false,
            timer: 2000,
          })
        })
        .catch(async error => {
          await this.closeModal()
          await this.$swal({
            icon: 'error',
            title: 'Erro ao atualizar dados da empresa!',
            showConfirmButton: false,
            text: error,
          })
        })
    },
  },
}
</script>
